<template>
    <div>
        <p v-if="props.state.multiFactorState === 2">Enter the token that was sent to your phone</p>
        <p v-else>Login via SMS does not require you to set up a password. Instead you will use token send to your registered mobile phone.</p>
        <div class="mb-3" v-if="props.state.multiFactorState === 2">
            <label class="form-label" for="token">Token</label>
            <input v-on:keyup.enter="authenticate" :disabled="isAuthenticated" type="text" name="token" v-model="token" id="token" class="form-control" :class="{ 'is-invalid' : errors.token }" autocomplete="token" required />
            <span class="invalid-feedback mt-0" v-if="errors.token">{{ errors.token }}</span>
        </div>
        <div class="mb-3" v-else>
            <label class="form-label" for="mobile">{{ usernameLabel }}</label>
            <input v-on:keyup.enter="authenticate" :disabled="isAuthenticated" type="tel" name="username" v-model.trim="trimmedUsername" id="mobile" class="form-control" :class="{ 'is-invalid' : errors.username }" autocomplete="username" required />
            <span class="invalid-feedback mt-0" v-if="errors.username">{{ errors.username }}</span>
        </div>
        
        <ErrorComponent v-if="errors" :errors="errors" :capsLockOn="capsLockOn" :key="state.updated" /> 

        <div class="form-group mb-2">
            <button type="button" :disabled="isAuthenticated" class="btn btn-o365-login" @click="authenticate()">{{ isAuthenticated ? 'Logged in' : props.state.multiFactorState === 2 ? 'Verify Token' : 'Send me a Token'}}</button>
        </div>
    </div>
</template>

<style scoped>
    .btn-show-pass {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 10px;
        display: none;
        font-size: 20px;
    }
    .form-floating:not(input.is-invalid):hover .btn-show-pass {
        display: block;
    }
    .form-horizontal{
        font-size:13px;
        font-family: Roboto, sans-serif;
    }
    /* .form-control { */
        /* height: calc(3rem + calc(var(--bs-border-width) * 2)); */
    /* } */
    
</style>

<script setup lang="ts">
    import { ref, computed, inject, onMounted } from 'vue'
    import { createRequest, MultiFactorStates } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue'; 

    const setErrors = inject('setErrors') as Function;
    
    const authenticated = inject('authenticated') as Function;
    const resetState = inject('resetState') as Function;
    const isBusy = inject('isBusy') as Function;

    const props = defineProps({ 
        state: { type: Object, required: true },
        errors: { type: Object, required: false, default: {}}, 
        name:String,
        title:String,
        capsLockOn: { type: Boolean, default: false }
    });

    onMounted(() => {
        if(props.state.multiFactorState === MultiFactorStates.Verify){
            attachResetStateEvets();
        }
        if(props.state.multiFactorState === MultiFactorStates.Verified &&
            props.state.currentProvider === 'sms' &&
            props.state.scheme === 'SmsToken'){
            authenticate();
        }
        isBusy(false);
    });

    const usernameLabel = ref('Mobile No');
    const username = ref('');
    const token = ref('');

    const isAuthenticated = computed(() => props.state?.isAuthenticated);
    const trimmedUsername = computed({
        get() {
            return username.value;
        },
        set(value) {
            username.value = value?.replace(/\s/g, '');
            validate();
        }
    });

    function validate(){
        setErrors({});
        if(props.state.multiFactorState === MultiFactorStates.Verify && !token.value.length){
             setErrors('Token field is required');
             return false;
        } else if(props.state.multiFactorState === 0 && !username.value.length){
            setErrors('Mobile no field is required');
            return false;
        }
        if(props.state?.isAuthenticated) return false;
        return true;
    }

    async function authenticate() {
        try {
            if(!validate()) {
                return;
            }
            isBusy(true);
            var data = {};
            if(props.state.multiFactorState === 2){
                data['token'] = token.value;
            } else {
                data['username'] = username.value;
                attachResetStateEvets();
            }
            const response = await createRequest('/api/login/sms', data);                   
            if(response.ok){
                const resp = await response.json();
                if(resp.multiFactorState === MultiFactorStates.Verified){
                    authenticated();
                    return;
                }
                isBusy(false);
                props.state.scheme = resp.scheme;
                props.state.action = resp.action;
                props.state.currentComponent = resp.currentComponent;
                props.state.multiFactorState = resp.multiFactorState;
                props.state.username = trimmedUsername.value;
            } else if(response.status == 400){
                const err = await response.json();
                isBusy(false);
                props.state.multiFactorState = MultiFactorStates.Login;                
                if(err.errors?.error){
                    setErrors(err.errors?.error);
                }
                if(err.error){
                    setErrors(err.error);
                }
                if(err.errors.Token){
                    setErrors(err.errors.Token);
                }
                if(err.errors.Username) {
                    setErrors(err.errors.Username);
                }       
            } else {
                const text = await response.text();
                setErrors(text);
            }            
        } catch(e) {
            console.log(e);
            isBusy(false);
        }
    }

    function attachResetStateEvets(){
        var tab = document.getElementsByClassName("nav nav-tabs");
        if(!tab.length) return;
        tab[0].addEventListener('click', function (evt) {
            var taget = <Element>evt.target;
            console.log(taget);
            console.log(taget.tagName);
            if (taget.classList.contains('nav-item')
                || taget.tagName.toLowerCase() === 'li'
                || taget.tagName.toLowerCase() === 'button'
                || taget.tagName.toLowerCase() === 'b') {
                console.warn('Tab was clicked');
                resetState();
            }
        }, { once: true });
    }

</script>